<template>
  <div>
    <div class="hero">
      <ZContainer>
        <ZImage :src="`${$config.staticAssetsURL}/web/images/empty-rv.svg`" />

        <h1 data-testid="rv-unpublished-error-title">
          {{ $t('pages.error.title.rvUnpublished') }}
        </h1>
        <p>{{ $t('pages.error.body.rvUnpublished') }}</p>
      </ZContainer>
    </div>

    <div class="content">
      <ZContainer>
        <div class="content__title-bar d-flex align-items-center">
          <div class="flex-grow-1">
            <h2>{{ $t('pages.error.rvUnpublished.title') }}</h2>
            <p>
              {{ $t('pages.error.rvUnpublished.subtitle', { place: `${error.rv.City}, ${error.rv.State}` }) }}
            </p>
          </div>
          <ZButton
            v-if="!isWebview"
            :to="urlToSearchInRvCity"
            class="d-none d-lg-inline-block"
          >
            {{ $t('pages.error.rvUnpublished.moreRvs') }}
          </ZButton>
        </div>
      </ZContainer>

      <div
        class="content__rv-cards"
        :class="{ 'zcontainer': !shouldUseSlider, 'pl-3': shouldUseSlider }"
      >
        <ContentSimilarRvs
          v-slot="{ rvs }"
          :rv-id="rvUnpublished.RVId"
          :quantity="9"
          @loaded="mountSlider"
        >
          <LazyGlide
            v-if="shouldUseSlider"
            ref="glideEl"
            :slides="rvs"
          >
            <template #slide="{ slide: rv }">
              <LazyCardRv
                :rv="rv"
                search-request-id="none"
                cta="unpublished"
                track-visiblity
                class="slider-card"
                :page-size="9"
              />
            </template>
          </LazyGlide>
        </ContentSimilarRvs>
      </div>

      <div
        v-if="!isWebview"
        class="content__footer"
      >
        <ZButton :to="urlToSearchInRvCity">
          {{ $t('pages.error.rvUnpublished.moreRvs') }}
        </ZButton>
      </div>
    </div>
  </div>
</template>

<script>
import { QueryStrings } from '~/lib/enums'
import { isWebview } from '~/lib/useUtils'

export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      glide: null,
    }
  },

  computed: {
    isWebview() {
      return isWebview()
    },

    rvUnpublished() {
      return this.error.rv
    },

    urlToSearchInRvCity() {
      return this.localePath({
        name: 'rv-search',
        query: {
          [QueryStrings.searchAddress]: `${this.error.rv.City}, ${this.error.rv.State}`,
        },
      })
    },

    shouldUseSlider() {
      return this.$device.isMobileOrTablet
    },
  },

  beforeUnmount() {
    this.glide?.destroy()
  },

  methods: {
    async mountSlider() {
      const { default: Glide, Swipe } = await import('@glidejs/glide/dist/glide.modular.esm')

      if (!this.shouldUseSlider || this.glide) {
        return
      }
      setTimeout(() => {
        this.glide = new Glide(this.$refs.glideEl.$el, { type: 'slider' })
        this.glide.mount({ Swipe })
      }, 400)
    },
  },
}
</script>

<style lang="scss" scoped>
.hero {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: getColor('highlight-50');
  text-align: center;

  @include media-min-size(large) {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  img {
    width: 4.8125rem;
    height: 2.6875rem;
    margin-bottom: 1.25rem;
  }

  h1 {
    @include title-1;
    margin-bottom: 0.625rem;
    color: getColor('primary-500');
  }

  p {
    @include regular-weight;

    margin-bottom: 0;
    color: #1f4f80;

    @include media-min-size(large) {
      // TODO: Find proper text style
      font-size: 1.125rem;
    }
  }
}

.content {
  padding: 1.875rem 0;
  background: #fff;

  @include media-min-size(large) {
    padding: 2.8125rem 0;
  }

  &__title-bar {
    margin-bottom: 1.875rem;
    text-align: center;

    @include media-min-size(large) {
      text-align: left;
    }

    h2 {
      @include subtitle-1;
      margin-bottom: 0.625rem;
      color: getColor('primary-500');
    }

    p {
      @include regular-weight;

      margin-bottom: 0;
      color: #1f4f80;
    }
  }

  &__rv-cards {
    :deep() {
      .slider-card {
        max-width: 18.75rem;
        margin: 0 1rem;
      }

      .no-similar-rvs-found {
        text-align: center;
      }
    }
  }

  &__footer {
    margin-top: 1.875rem;
    text-align: center;

    @include media-min-size(large) {
      margin-top: 5rem;
    }
  }
}
</style>

<i18n src="~/locales/common/pages/error.json" lang="json" />
